
import './App.css';
import React, { Suspense, lazy } from 'react';
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { UserAuthContextProvider } from "./firebase2/UserAuthContext";


import Loading from './Components/Navbar/Loading';
import Homepage from './Components/Homepage/Home';

//import NewOrder from './Components/NewOrder/NewOrder';
const AboutUs = lazy(() => import('./Components/Homepage/AboutUs'));
const OurServiceArea = lazy(() => import('./Components/Homepage/OurServiceArea'));
const FarmerPage = lazy(() => import('./Components/Homepage/FarmerPage'));
const DriverPage = lazy(() => import('./Components/Homepage/DriverPage'));
const CustomerPush = lazy(() => import('./Components/AdminComponents/Orders/CustomerPush'));
const LogOut = lazy(() => import('./Components/Auth/LogOut'));
const LookingSupplier = lazy(() => import('./Components/Homepage/LandingPages/Suppliers/LookingSuppliers'));
const ChooseBeef = lazy(() => import('./Components/NewOrder/NewOrder1/ChooseBeef'));
const ChooseChicken = lazy(() => import('./Components/NewOrder/NewOrder1/ChooseChicken'));
const ChooseCheese = lazy(() => import('./Components/NewOrder/NewOrder1/ChooseCheese'));
const ChooseMutton = lazy(() => import('./Components/NewOrder/NewOrder1/ChooseMutton'));
const ChooseRabbit = lazy(() => import('./Components/NewOrder/NewOrder1/ChooseRabbit'));
const ChooseMeat = lazy(() => import('./Components/NewOrder/NewOrder1/ChooseMeat'));
const ChooseSpice = lazy(() => import('./Components/NewOrder/NewOrder1/ChooseSpice'));
const ChooseItem = lazy(() => import('./Components/NewOrder/NewOrder1/ChooseItem'));

const ItemsGroupTable = lazy(() => import('./Components/AdminComponents/Items/ItemGroups'));
const ItemsTable = lazy(() => import('./Components/AdminComponents/Items/Items'));
const ButchersTable = lazy(() => import('./Components/AdminComponents/Butchers/Butchers'));
const Coupons = lazy(() => import('./Components/AdminComponents/Coupons/Coupons'));
const Coupon = lazy(() => import('./Components/AdminComponents/Coupons/Coupon'));

const SignIn = lazy(() => import('./Components/Auth/SignIn'));
const UserSignUp = lazy(() => import('./Components/Auth/UserSignUp'));
const ContactUs = lazy(() => import('./Components/Homepage/Contact/ContactUs'));
const ServiceAreaMap = lazy(() => import('./Components/Map/ServiceAreaMap'));
const ArchOrder = lazy(() => import('./Components/AdminComponents/ArchievedOrders/ArchOrder'));
const ArchOrders = lazy(() => import('./Components/AdminComponents/ArchievedOrders/ArchOrders'));
const DriverOrder = lazy(() => import('./Components/Driver/DriverOrder'));
const ShowMap = lazy(() => import('./Components/Map/ShowMap'));
const NewAdress = lazy(() => import('./Components/Auth/NewAdress'));
const AdminOrder = lazy(() => import('./Components/AdminComponents/Orders/AdminOrder'));
const AdminChangeOrder = lazy(() => import('./Components/AdminComponents/Orders/AdminChangeOrder'));
const Pricing = lazy(() => import('./Components/AdminComponents/Pricing/Pricing'));
const Users = lazy(() => import('./Components/AdminComponents/Users/Users'));

const AllOrders = lazy(() => import('./Components/AdminComponents/Orders/AllOrders'));
const Inventory = lazy(() => import('./Components/AdminComponents/Inventory/InventoryNow'));
const InventoryTransactions = lazy(() => import('./Components/AdminComponents/Inventory/InventoryTransactions'));
const InventoryIn = lazy(() => import('./Components/AdminComponents/Inventory/InventoryIn'));
const InventoryOut = lazy(() => import('./Components/AdminComponents/Inventory/InventoryOut'));
const InventoryFarmerOrders = lazy(() => import('./Components/AdminComponents/Inventory/InventoryFarmerOrders'));
const InventoryFarmerOrder = lazy(() => import('./Components/AdminComponents/Inventory/InventoryFarmerOrder'));
const InvArchFarmerOrders = lazy(() => import('./Components/AdminComponents/Inventory/InvArchFarmerOrders'));
const InvArchFarmerOrder = lazy(() => import('./Components/AdminComponents/Inventory/InvArchFarmerOrder'));
const InventoryLeftoverOrders = lazy(() => import('./Components/AdminComponents/Inventory/InventoryLeftoverOrders'));
const InventoryLeftoverOrder = lazy(() => import('./Components/AdminComponents/Inventory/InventoryLeftoverOrder'));
const InvArchLeftoverOrders = lazy(() => import('./Components/AdminComponents/Inventory/InvArchLeftoverOrders'));
const InvArchLeftoverOrder = lazy(() => import('./Components/AdminComponents/Inventory/InvArchLeftoverOrder'));
const InventoryDocs = lazy(() => import('./Components/AdminComponents/Inventory/InventoryDocs'));
const InventoryDoc = lazy(() => import('./Components/AdminComponents/Inventory/InventoryDoc'));
const Buyers = lazy(() => import('./Components/AdminComponents/Inventory/buyers'));

const ProductionOrder = lazy(() => import('./Components/AdminComponents/Production/ProductionOrder'));
const ProductionOrders = lazy(() => import('./Components/AdminComponents/Production/ProductionOrders'));
const ArhProdOrder = lazy(() => import('./Components/AdminComponents/Production/ArhProdOrder'));
const ArhProdOrders = lazy(() => import('./Components/AdminComponents/Production/ArhProdOrders'));
const InventoryForecast = lazy(() => import('./Components/AdminComponents/Inventory/InventoryForecast'));

const User = lazy(() => import('./Components/Auth/User'));
const NewOrder1 = lazy(() => import('./Components/NewOrder/NewOrder1/NewOrder1'));

//const CheckOut = lazy(() => import('./Components/NewOrder/CheckOut/CheckOut'));
const CheckOut2 = lazy(() => import('./Components/NewOrder/CheckOut2/CheckOut2'));
const CODelivery = lazy(() => import('./Components/NewOrder/CheckOut2/CODelivery'));
const COCustomer = lazy(() => import('./Components/NewOrder/CheckOut2/COCustomer'));
const COLogin = lazy(() => import('./Components/NewOrder/CheckOut2/COLogin'));
const COAddress = lazy(() => import('./Components/NewOrder/CheckOut2/COAddress'));
const COPayment = lazy(() => import('./Components/NewOrder/CheckOut2/COPayment'));
const COMpesa = lazy(() => import('./Components/NewOrder/CheckOut2/COMpesa'));
const COMpesaPaymentProcess = lazy(() => import('./Components/NewOrder/CheckOut2/COMpesaPaymentProcess'));
const COOpenOrder = lazy(() => import('./Components/NewOrder/CheckOut2/COOpenOrder'));


export default function App() {

	const role = useSelector((state) => state.auth.userInfo.role);

	const all = (role === 'User' || role === 'Farmer' || role === 'Driver' || role === "Inner" || role === "Admin");
	const user = (role === 'User' || role === "Inner" || role === "Admin");
	const inner = (role === "Inner" || role === "Admin");
	const farmer = (role === "Farmer" || role === "Admin");
	const driver = (role === "Driver" || role === "Admin");
	const admin = (role === "Admin");

	const theme = createTheme(
		{
			palette: {

				secondary: {
					main: "#dc004e",
				},
				grey: {
					main: "#C9C9C9",
					light: '#C8C8C8',
					dark: '#737373',
					contrastText: '#000000',
				}
			},
			typography: {
				fontFamily: [
					'Ubuntu', 'League Spartan', "-apple-system", "BlinkMacSystemFont", 'Segoe UI', 'Roboto', 'Oxygen',
				].join(','),
			}
		}
	);

	return (
		<ThemeProvider theme={theme}>
			<UserAuthContextProvider>
				<BrowserRouter>
					<Suspense fallback={<Loading />}>
						<Switch>
							{/* Open links */}
							<Route path="/signin" exact component={SignIn} />
							<Route path="/logout" exact component={LogOut} />
							<Route path="/" exact component={Homepage} />
							<Route path="/home" exact component={Homepage} />
							<Route path="/aboutus" exact component={AboutUs} />
							<Route path="/ourservicearea" exact component={OurServiceArea} />
							<Route path="/contact" exact component={ContactUs} />
							<Route path="/usersignup" exact component={UserSignUp} />
							<Route path="/neworder1" exact component={NewOrder1} />
							<Route path="/choosemeat" exact component={ChooseMeat} />
							<Route path="/choosebeef" exact component={ChooseBeef} />
							<Route path="/choosechicken" exact component={ChooseChicken} />
							<Route path="/choosecheese" exact component={ChooseCheese} />
							<Route path="/choosemutton" exact component={ChooseMutton} />
							<Route path="/chooserabbit" exact component={ChooseRabbit} />
							<Route path="/choosespice" exact component={ChooseSpice} />
							<Route path="/chooseitem/:group/:id" component={ChooseItem} />
							<Route path="/checkout" exact component={CheckOut2} />
							<Route path="/checkout2" exact component={CheckOut2} />
							<Route path="/codelivery" exact component={CODelivery} />
							<Route path="/cocustomer" exact component={COCustomer} />
							<Route path="/cologin" exact component={COLogin} />
							<Route path="/coaddress" exact component={user ? COAddress : Homepage} />
							<Route path="/copayment" exact component={user ? COPayment : Homepage} />
							<Route path="/coopenorder" exact component={user ? COOpenOrder : Homepage} />

							<Route path="/newaddress" exact component={NewAdress} />
							<Route path="/newsupplier" exact component={LookingSupplier} />

							{/* User links */}
							<Route path="/user" exact component={all ? User : Homepage} />
							<Route path="/paympesa" exact component={user ? COMpesa : Homepage} />
							<Route path="/paymentprocess" exact component={user ? COMpesaPaymentProcess : Homepage} />


							{/* Farmer links */}
							<Route path="/farmerpage" exact component={farmer ? FarmerPage : Homepage} />


							{/* Driver links */}
							<Route path="/driverpage" exact component={driver ? DriverPage : Homepage} />
							<Route path="/driverorder" exact component={driver ? DriverOrder : Homepage} />
							<Route path="/customerpush" exact component={driver ? CustomerPush : Homepage} />

							{/* Inner links */}
							<Route path="/allorders" exact component={inner ? AllOrders : Homepage} />
							<Route path="/inventory" exact component={inner ? Inventory : Homepage} />
							<Route path="/inventorytrans/:item/:transaction" component={inner ? InventoryTransactions : Homepage} />
							<Route path="/inventoryin" exact component={inner ? InventoryIn : Homepage} />
							<Route path="/inventoryout" exact component={inner ? InventoryOut : Homepage} />
							<Route path="/adminorder/:id" component={inner ? AdminOrder : Homepage} />
							<Route path="/inventfarmerorders" exact component={inner ? InventoryFarmerOrders : Homepage} />
							<Route path="/inventfarmerorder/:id" component={inner ? InventoryFarmerOrder : Homepage} />
							<Route path="/invarhfarmorders" exact component={inner ? InvArchFarmerOrders : Homepage} />
							<Route path="/invarhfarmorder/:id" component={inner ? InvArchFarmerOrder : Homepage} />
							<Route path="/inventleftoverorders" exact component={inner ? InventoryLeftoverOrders : Homepage} />
							<Route path="/inventleftoverorder/:id" component={inner ? InventoryLeftoverOrder : Homepage} />
							<Route path="/invarhleftoverorders" exact component={inner ? InvArchLeftoverOrders : Homepage} />
							<Route path="/invarhleftoverorder/:id" component={inner ? InvArchLeftoverOrder : Homepage} />
							<Route path="/inventorydocs" exact component={inner ? InventoryDocs : Homepage} />
							<Route path="/inventorydoc/:id" component={inner ? InventoryDoc : Homepage} />
							<Route path="/inventoryforecast" exact component={inner ? InventoryForecast : Homepage} />
							<Route path="/productionorders" exact component={inner ? ProductionOrders : Homepage} />
							<Route path="/productionorder/:id" component={inner ? ProductionOrder : Homepage} />
							<Route path="/arhprodorders" exact component={inner ? ArhProdOrders : Homepage} />
							<Route path="/arhprodorder/:id" component={inner ? ArhProdOrder : Homepage} />
							<Route path="/adminchangeorder/:id" component={inner ? AdminChangeOrder : Homepage} />
							<Route path="/buyers" component={inner ? Buyers : Homepage} />

							{/* Admin links */}
							<Route path="/allusers" exact component={admin ? Users : Homepage} />
							<Route path="/pricing" exact component={admin ? Pricing : Homepage} />
							<Route path="/showmap" exact component={admin ? ShowMap : Homepage} />
							<Route path="/archorders" exact component={admin ? ArchOrders : Homepage} />
							<Route path="/archorder/:id" component={admin ? ArchOrder : Homepage} />
							<Route path="/servicearea" exact component={admin ? ServiceAreaMap : Homepage} />
							<Route path="/coupons" exact component={admin ? Coupons : Homepage} />
							<Route path="/coupon/:id" component={admin ? Coupon : Homepage} />
							<Route path="/items" component={admin ? ItemsTable : Homepage} />
							<Route path="/itemgroups" component={admin ? ItemsGroupTable : Homepage} />
							<Route path="/butchers" component={admin ? ButchersTable : Homepage} />

						</Switch>
					</Suspense>
				</BrowserRouter>
			</UserAuthContextProvider>
		</ThemeProvider>
	);

}
