

const homeContentStyle = {
  /// CONTENT

  headline: {
    display: "flex",
    fontFamily: "League Spartan",
    width: "59vh",
    textAlign: "center",
    marginTop: "20.8vh",
    marginBottom: "2.1vh",
    color: "#FFFFFF",
    fontWeight: 600,
    fontSize: "5.8vh",
    "@media (max-width: 700px)": {
      textAlign: "center",
      width: "28.9vh",
      fontSize: "4.7vh",
      marginTop: "16.7vh",
      marginBottom: "1.3vh",
    },
  },
  headText: {
    display: "flex",
    fontFamily: "League Spartan",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "0px",
    marginTop: "0px",
    color: "#FFFFFF",
    fontWeight: 500,
    fontSize: "2.3vh",
    "@media (max-width: 700px)": {
      fontSize: "2.6vh",
      width: "34vh"
      
    },
  },
  mainButton:{
    padding:"0px",
    background: "#FBEB00",
    justifyContent: "center",
    textTransform: "none",
    color: "#000000",
    fontFamily: "Ubuntu",
    fontWeight: 500,
    fontSize: "3.2vh",
    height: "6.4vh",
    width: "32vh",
    marginTop: "3.6vh",
    "@media (max-width: 700px)": {
      fontSize: "3.2vh",
      height: "7.2vh",
      width: "35vh",
      marginTop: "3.2vh",
      marginBottom: "1vh",
    },
  },
  homeMurtud: {
    
    width: "100%",
    marginTop: "-6vh",
    "@media (max-width: 700px)": {
      marginTop: "-4vh",
    },
  },

  /// PROMOBOX

  homeBody: {
    marginTop: "0",
  },
  promoBox: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    width: "22vh",
    height: "17.05vh",
    marginTop: "-13vh",
    background: "#212121",
    borderRadius: "1.1vh",
    "@media (max-width: 700px)": {
      marginTop: "-12vh",
      width: "18vh",
    height: "13.95vh",
    },
  },
  promoBoxInside: {
    padding: "3.3vh",
    "@media (max-width: 700px)": {
      padding: "1.2vh",
    },
  },
  promoBoxBetween: {
    width: "3.3vh",
    marginTop: "-10vh",
    height: "13.4vh",
    minHeight: "5.5vh",
    "@media (max-width: 700px)": {
      marginTop: "-10vh",
      width: "2.2vh",
    },
  },
  promoTextSmallYellow: {
    color: `#FCEE23`,
    fontFamily: "Ubuntu",
    fontWeight: 500,
    fontSize: "1.78vh",
    marginTop: 0,
    marginBottom: 0,
  },
  promoTextLargeYellow: {
    fontFamily: "League Spartan",
    color: `#FCEE23`,
    fontWeight: 600,
    fontSize: "4.4vh",
    marginTop: "0.44vh",
    "@media (max-width: 700px)": {
      fontSize: "3.9vh",
    },

  },
  promoTextMediumYellow: {
    fontFamily: "League Spartan",
    color: `#FCEE23`,
    fontWeight: 600,
    fontSize: "4.2vh",
    marginTop: "4px",
    "@media (max-width: 700px)": {
      fontSize: "3.8vh",
    },
  },
  promoTextLargeWhite: {
    fontFamily: "Ubuntu",
    color: `#FFFFFF`,
    fontWeight: 500,
    fontSize: "20px",
  },
  promoTextSmallWhite: {
    fontFamily: "League Spartan",
    color: `#FFFFFF`,
    fontWeight: 600,
    fontSize: "2.1vh",
    marginTop: "0.66vh",
    marginBottom: "0.6vh",
    "@media (max-width: 700px)": {
      fontSize: "2.4vh",
    },
  },

  /// BOTTOM BAR
  bottomBarArea: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: "6.2vh",
    minHeight: "36px",
    background: "#000000",
    maxWidth: "100%",
    "@media (max-width: 600px)": {
      height: "87px",
    },
  },
  bottomBarIconsArea: {
    marginLeft: "2vh",
  },
  socialMediaButton: {
    color: "#FFFFFF",
    marginLeft: "1.7vh",
    padding: 0,
    "@media (max-width: 600px)": {
      marginLeft: "0px",
      marginRight: "14px",
    },
  },
  bottomEmail: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "6.2vh",
    minHeight: "36px",
    fontFamily: "Ubuntu",
    textAlign: "center",
    color: "#FFFFFF",
    fontWeight: 400,
    fontSize: "16px",
    "@media (max-width: 600px)": {
      height: "16px",
      minHeight: "16px",
      marginBottom: "10px",
    },
  },
  callCircleDescktop: {
    width: "9.0vh",
    height: "9.0vh",
    minWidth: "7.6vh",
    minHeight: "7.6vh",
    borderRadius: "50%",
    position: "absolute",
    bottom: "2.75vh",
    right: "2.75vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#25D366",
    "@media (max-width: 600px)": {
      width: "60px",
      height: "60px",
      minWidth: "60px",
      minHeight: "60px",
      bottom: "13px",
      right: "13px",
    },
  },
  whatsappMain: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
    width: "6vh",
    height: "6vh",
    minWidth: "5.04vh",
    minHeight: "5.04vh",
    "@media (max-width: 600px)": {
      width: "57px",
      height: "57px",
      minWidth: "57px",
      minHeight: "57px",
    },
  },
  whatsappSVG: {

    "@media (max-width: 600px)": {
    
    },
  },
};

export default homeContentStyle;
