import { createContext, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail 
} from "firebase/auth"; 
import { auth, db } from "./firebaseConf";
import { doc, getDoc } from "firebase/firestore";
import * as authActions from "../Store/actions/authActions";
//  authCheck, authInProcess, authUserID,  userInfoChange,
import * as actionCreators from "../Store/actions/actions";

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [userD, setUserD] = useState({});
  const dispatch = useDispatch();

  function logIn(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }
  function signUp(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }
  function logOut() {
    return signOut(auth);
  }
  function googleSignIn() {
    const googleAuthProvider = new GoogleAuthProvider();
    return signInWithPopup(auth, googleAuthProvider);
  }
  function passwordReset(email) {
    return sendPasswordResetEmail(auth, email);
  }


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
      dispatch(authActions.authInProcess(null));
      //console.log("wa miskit hakkab toimuma, user:", currentuser);
      if (currentuser) {
        dispatch(authActions.authCheck(true));
        dispatch(authActions.authUserID(currentuser.uid));
        const userDocRef = doc(db, "users", currentuser.uid);
        getDoc(userDocRef)
          .then((userDoc) => {
            //console.log("db user toimis");
            if (userDoc.exists()) {
              //console.log("user andmebaasist leitud", userDoc.data());
              dispatch(authActions.userInfoChange(userDoc.data()));
              const userOrderRef = doc(db, "userOrders", currentuser.uid);
              getDoc(userOrderRef)
                .then((userOrder) => {
                    //console.log("userOrder paring tehtud", userOrder.data());
                  if (userOrder.exists()) {
                    //console.log("userOrder exists", userOrder.data());
                    const orderRef = doc(db, "orders", userOrder.data().order);
                    getDoc(orderRef)
                      .then((order) => {
                        dispatch(actionCreators.newOrder({
                          ...order.data(),
                          id: userOrder.data().order,
                        }));
                        dispatch(authActions.authInProcess(false));

                        //console.log("sain orderi");
                      })
                      .catch((error) => {
                        //console.log("Error getting order:", error);
                      });
                  } else {
                    dispatch(actionCreators.newOrder(null));
                    dispatch(authActions.authInProcess(false));

                    //console.log("useril orderit polnud");
                  }
                })
                .catch((error) => {
                  //console.log("Error getting userOrder:", error);
                });
            } else {
              //console.log("No such uid!");
            }
          })
          .catch((error) => {
            //console.log("Error getting uid document:", error);
          });
      } else {
        dispatch(authActions.authCheck(false));
        dispatch(authActions.authUserID(null));
        dispatch(authActions.userInfoChange({}));
        dispatch(authActions.authInProcess(false));
        //console.log("wa kasutajat pole");
      }
      setUserD(currentuser);
      ///////
    });
    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <userAuthContext.Provider
      value={{ userD, logIn, signUp, logOut, passwordReset, googleSignIn }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}
