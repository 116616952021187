const initialState = {
  loading: false,
  chooseMbuzi: {
    age: 1,
    size: 1,
    live: false,
    sex: "M",
    color: "white",
    qty: 1,
  },
  order: null,
  adminOrder: null,
  payment: null,
  orderList: null,
  filteredOrderList: null,
  userList: null,
  prices: null,
  cart: {
    total: 0,
    cartTotal: 0,
    items: [],
  },
  address: {},
  company: null,
  farmers: null,
  drivers: null,
  orderCount: null,
  deliveryTime: null,
  coupon: null,
  farmerPages: null,
  filter: "",
  formParams: {
    order: "desc",
    orderBy: "date",
    rowsPerPage: 25,
    page: 0,
    filter: "",
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHOOSE_MBUZI":
      return {
        ...state,
        chooseMbuzi: action.mbuzi,
      };
    case "NEW_ORDER":
      return {
        ...state,
        order: action.order,
      };
    case "NEW_ADMINORDER":
      return {
        ...state,
        adminOrder: action.order,
      };
    case "NEW_DELIVERYTIME":
      return {
        ...state,
        deliveryTime: action.deliveryTime,
      };
    case "ORDERS_TO_STATE":
      return {
        ...state,
        orderList: action.orders,
      };
    case "FILTEREDORDERS_TO_STATE":
      return {
        ...state,
        filteredOrderList: action.orders,
      };
    case "USERS_TO_STATE":
      return {
        ...state,
        userList: action.users,
      };

    case "ADDRESS_TO_STATE":
      return {
        ...state,
        address: action.address,
      };
      case "COMPANY_TO_STATE":
      return {
        ...state,
        company: action.company,
      };
    case "FARMERS_TO_STATE":
      return {
        ...state,
        farmers: action.farmers,
      };
    case "DRIVERS_TO_STATE":
      return {
        ...state,
        drivers: action.drivers,
      };
    case "LOGOUT_DATA":
      return {
        ...state,
        order: null,
        orderList: null,
        userList: null,
      };

    case "PRICES_TO_STATE":
      return {
        ...state,
        prices: action.prices,
      };

    case "CART_TO_STATE":
      return {
        ...state,
        cart: action.cart,
      };

    case "COUPON_TO_STATE":
      return {
        ...state,
        coupon: action.coupon,
      };

    case "CHANGE_LOADING":
      return {
        ...state,
        loading: action.loading,
      };

    case "PARAMS_TO_STATE":
      return {
        ...state,
        formParams: action.params,
      };
    case "PAYMENT_TO_STATE":
      return {
        ...state,
        payment: action.payment,
      };

    case "FARMERPAGES_TO_STATE":
      return {
        ...state,
        farmerPages: action.farmerPages,
      };

    default:
      return state;
  }
};
export default reducer;
