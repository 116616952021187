//background: 'linear-gradient(to left, #7b4397, #dc2430)'

const drawerWidth = 260;

const background = {
  textDecoration: "none",
  height: "150vh",
  margin: 0,
  padding: 0,
  border: 0,
  alignItems: "center",
  background: `#1C1E1E`,
};
const backgroundP = {
  textDecoration: "none",
  minHeight: "100vh",
  height: "auto",
  margin: 0,
  padding: 0,
  border: 0,
  alignItems: "center",
  backgroundSize: "cover",
  backgroundPosition: "top center",
  "&:before": {
    background: "rgba(0, 0, 0, 0.5)",
  },
  "& footer li a,& footer li a:hover,& footer li a:active": {
    color: "#FFFFFF",
  },
  "& footer": {
    position: "absolute",
    bottom: "0",
    width: "100%",
  },
};

const logoArea = {
  paddingTop: "75px",
  marginLeft: "auto",
  marginRight: "auto",
  "@media (min-width: 200px)": {
    height: "110px",
    width: "110px",
  },
  "@media (min-width: 576px)": {
    height: "150px",
    width: "150px",
  },
};

const logoAreaSmall = {
  paddingTop: "20px",
  height: "100px",
  width: "100px",
  marginLeft: "auto",
  marginRight: "auto",
};

const logo = {
  height: "100%",
  opacity: 1,
  alignItems: "center",
  marginLeft: "auto",
  marginRight: "auto",
  position: "relative",
  textAlign: "center",
};

const logoSmall = {
  height: "100%",
  opacity: 1,
  alignItems: "center",
  marginLeft: "auto",
  marginRight: "auto",
  position: "relative",
  textAlign: "center",
};
const tag = {
  height: "100%",
  opacity: 1,
  alignItems: "center",
  marginLeft: "auto",
  marginRight: "auto",
  position: "relative",
  textAlign: "center",
};
const tagArea = {
  paddingTop: "20px",
  height: "50px",
  width: "50px",
  marginLeft: "auto",
  marginRight: "auto",
};

const mainButton = {
  color: "#E7B400",
  background: "transparent",
  border: "2px solid",
  borderColor: "#E7B400",
  borderRadius: "11px 11px 0px 11px",
  fontWeight: "800",
  fontSize: "20px",
  letterSpacing: "0px",
  "&:hover": {
    color: "#E7B400",
    background: "#FFFFFF",
    boxShadow: "none",
    textDecoration: "none",
  },
};

const smallButton = {
  marginTop: "1rem",
  color: "#E7B400",
  boxShadow: "none",
  background: "transparent",
  border: "2px solid",
  borderColor: "#E7B400",
  borderRadius: "9px 9px 0px 9px",
  fontWeight: "600",
  fontSize: "14px",
  letterSpacing: "0px",
  "&:hover": {
    color: "#E7B400",
    background: "#FFFFFF",
    boxShadow: "none",
    textDecoration: "none",
  },
};
const smallBlackButton = {
  marginTop: "1rem",
  color: "#1C1E1E",
  boxShadow: "none",
  background: "transparent",
  border: "2px solid",
  borderColor: "#1C1E1E",
  borderRadius: "9px 9px 0px 9px",
  fontWeight: "600",
  fontSize: "14px",
  letterSpacing: "0px", 
  "&:hover": {
    color: "#E7B400",
    background: "#FFFFFF",
    boxShadow: "none",
    textDecoration: "none",
  },
};

const chooseButton = {
  fontFamily: 'Ubuntu',
  marginTop: "8px",
  paddingLeft:"10px",
  paddingRight:"10px",
  color: "#000000",
  background: "transparent",
  borderRadius: "11px 11px 0px 11px",
  fontWeight: "400",
  fontSize: "12px",
  letterSpacing: "0px",
  display: "Block",
  "&:hover": {
    color: "#000000",
    background: "#FCEE23",
    boxShadow: "none",
    textDecoration: "none",
  },
};

const chooseButtonSelected = {
  fontFamily: 'Ubuntu',
  marginTop: "8px",
  paddingLeft:"10px",
  paddingRight:"10px",
  color: "#000000",
  background: "#FCEE23",
  borderRadius: "3px",
  boxShadow: "none",
  fontWeight: "400",
  fontSize: "12px",
  letterSpacing: "0px",
  display: "Block",
  "&:hover": {
    color: "#000000",
    background: "#FCEE23",
    boxShadow: "none",
    textDecoration: "none",
  },
};

const transition = {
  transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)",
};

const conatinerFluid = {
  paddingRight: "15px",
  paddingLeft: "15px",
  marginRight: "auto",
  marginLeft: "auto",
  width: "100%",
};
const container = {
  ...conatinerFluid,
  "@media (min-width: 576px)": {
    maxWidth: "540px",
  },
  "@media (min-width: 768px)": {
    maxWidth: "720px",
  },
  "@media (min-width: 992px)": {
    maxWidth: "960px",
  },
  "@media (min-width: 1200px)": {
    maxWidth: "1140px",
  },
};

const boxShadow = {
  boxShadow:
    "0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
};

const card = {
  display: "inline-block",
  position: "relative",
  width: "100%",
  margin: "25px 0",
  boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
  borderRadius: "3px",
  color: "rgba(0, 0, 0, 0.87)",
  background: "#E4E4E4",
};

const defaultFont = {
  fontFamily: '"Ubuntu","Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: "400",
  lineHeight: "1.5em",
};

const primaryColor = "#9c27b0";
const warningColor = "#ff9800";
const dangerColor = "#f44336";
const successColor = "#4caf50";
const infoColor = "#00acc1";
const roseColor = "#e91e63";
const grayColor = "#999999";

const primaryBoxShadow = {
  boxShadow: 0,
};
const infoBoxShadow = {
  boxShadow:
    "0 12px 20px -10px rgba(0, 188, 212, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 188, 212, 0.2)",
};
const successBoxShadow = {
  boxShadow:
    "0 12px 20px -10px rgba(76, 175, 80, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(76, 175, 80, 0.2)",
};
const warningBoxShadow = {
  boxShadow:
    "0 12px 20px -10px rgba(255, 152, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 152, 0, 0.2)",
};
const dangerBoxShadow = {
  boxShadow:
    "0 12px 20px -10px rgba(244, 67, 54, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(244, 67, 54, 0.2)",
};
const roseBoxShadow = {
  boxShadow:
    "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4)",
};

const warningCardHeader = {
  color: "#fff",
  background: "linear-gradient(60deg, #ffa726, #fb8c00)",
  ...warningBoxShadow,
};
const successCardHeader = {
  color: "#fff",
  background: "linear-gradient(60deg, #66bb6a, #43a047)",
  ...successBoxShadow,
};
const dangerCardHeader = {
  color: "#fff",
  background: "linear-gradient(60deg, #ef5350, #e53935)",
  ...dangerBoxShadow,
};
const infoCardHeader = {
  color: "#fff",
  background: "linear-gradient(60deg, #26c6da, #00acc1)",
  ...infoBoxShadow,
};
const primaryCardHeader = {
  color: "#E7B400",
  background: "#1C1E1E",
  ...primaryBoxShadow,
};
const roseCardHeader = {
  color: "#fff",
  background: "linear-gradient(60deg, #ec407a, #d81b60)",
  ...roseBoxShadow,
};
const cardActions = {
  margin: "0 20px 10px",
  paddingTop: "10px",
  borderTop: "1px solid #eeeeee",
  height: "auto",
  ...defaultFont,
};

const cardHeader = {
  textAlign: "center",
};

const defaultBoxShadow = {
  border: "0",
  borderRadius: "3px",
  boxShadow:
    "0 10px 20px -12px rgba(0, 0, 0, 0.42), 0 3px 20px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
  padding: "10px 0",
  transition: "all 150ms ease 0s",
};

const title = {
  color: "#3C4858",
  margin: "1.75rem 0 0.875rem",
  textDecoration: "none",
  fontWeight: "700",
  fontFamily: `"Roboto Slab", "Times New Roman", serif`,
};

const cardTitle = {
  ...title,
  marginTop: ".625rem",
};

const cardLink = {
  "& + $cardLink": {
    marginLeft: "1.25rem",
  },
};

const cardSubtitle = {
  marginBottom: "0",
  marginTop: "-.375rem",
};

const tooltip = {
  padding: "10px 15px",
  minWidth: "130px",
  color: "#555555",
  lineHeight: "1.7em",
  background: "#FFFFFF",
  border: "none",
  borderRadius: "3px",
  boxShadow:
    "0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2)",
  maxWidth: "200px",
  textAlign: "center",
  fontFamily: '"Helvetica Neue",Helvetica,Arial,sans-serif',
  fontSize: "0.875em",
  fontStyle: "normal",
  fontWeight: "400",
  textShadow: "none",
  textTransform: "none",
  letterSpacing: "normal",
  wordBreak: "normal",
  wordSpacing: "normal",
  wordWrap: "normal",
  whiteSpace: "normal",
  lineBreak: "auto",
};

export {
  //variables
  background,
  backgroundP,
  logo,
  logoSmall,
  tag,
  tagArea,
  logoArea,
  logoAreaSmall,
  mainButton,
  smallButton,
  smallBlackButton,
  chooseButton,
  chooseButtonSelected,
  tooltip,
  drawerWidth,
  transition,
  container,
  conatinerFluid,
  boxShadow,
  card,
  defaultFont,
  primaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  primaryBoxShadow,
  infoBoxShadow,
  successBoxShadow,
  warningBoxShadow,
  dangerBoxShadow,
  roseBoxShadow,
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader,
  cardActions,
  cardHeader,
  defaultBoxShadow,
  title,
  cardTitle,
  cardLink,
  cardSubtitle,
};
