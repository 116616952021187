import React, { lazy, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";

import AppBar from "@material-ui/core/AppBar";
import withStyles from "@material-ui/core/styles/withStyles";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import CloseIcon from "@material-ui/icons/Close";

import Menu from "@material-ui/icons/Menu";
import navbarStyle from "./navbarStyle";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Badge from "@material-ui/core/Badge";

//import { Apps, CloudDownload, AccountCircle, EmojiObjects } from "@material-ui/icons";

import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import MbuziLogo from "../../Assets/logo";
import { Grid } from "@material-ui/core";

import CustomDropdown from "./CustomDropdown";
//const CustomDropdown = lazy(() => import("./CustomDropdown"));
const ShopBar = lazy(() => import("./ShopBar"));
const MenuExpand = lazy(() => import("./MenuExpand"));
const MobileMenuList = lazy(() => import("./MobileMenuList"));

const ListButton = withStyles({
  root: {
    color: "#FFFFFF",
    fontFamily: "Ubuntu",
    fontWeight: 400,
    textTransform: "none",
    fontSize: 16,
    marginLeft: " 0px !important",
    "&:hover": {
      color: "#FBEB00 !important",
    },
    "@media (max-width: 959.95px)": {
      marginLeft: 0,
    },
  },
})(Button);
const LoginButton = withStyles({
  root: {
    color: "#212121",
    width: "200px",
    height: "35px !important",
    minHeight: "2.0rem !important",
    padding: "0px !important",
    backgroundColor: "#FBEB00",
    fontFamily: "Ubuntu",
    fontWeight: "500 !important",
    textTransform: "none",
    fontSize: "18px !important",
    marginLeft: " 15px !important",
    marginTop: " 20px !important",
    "&:hover": {
      color: "#FBEB00",
    },
    "@media (max-width: 959.95px)": {
      marginLeft: 0,
    },
  },
})(Button);
const LoginTopButton = withStyles({
  root: {
    color: "#212121",
    width: "95px",
    height: "35px !important",
    minHeight: "2.0rem !important",
    padding: "0px !important",
    backgroundColor: "#FBEB00",
    fontFamily: "Ubuntu",
    fontWeight: "500 !important",
    textTransform: "none",
    fontSize: "18px !important",
    marginLeft: " 15px !important",
    "&:hover": {
      color: "#FBEB00",
    },
    "@media (max-width: 959.95px)": {
      marginLeft: 0,
    },
  },
})(Button);

let myCart = "";

const useStyles = makeStyles(navbarStyle);

export default function Navbar(props) {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);
  const isAuth = useSelector((state) => state.auth.isAuth);
  const userInfo = useSelector((state) => state.auth.userInfo);
  const authInProcess = useSelector((state) => state.auth.authInProcess);
  const cart = useSelector((state) => state.reducer.cart);

  const [brandLink, setBrandLink] = useState("/");
  const [auth, setAuth] = useState("");
  const [auth1, setAuth1] = useState("");
  const [auth2, setAuth2] = useState("");

  const { color, fixed, absolute, shopBar, shop } = props;

  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed,
  });
  const appBarClasses1 = classNames({
    [classes.appBar1]: true,
    [classes["white"]]: "white",
    [classes.absolute]: absolute,
    [classes.fixed]: fixed,
  });


  useEffect(() => {
    let innerLinks=[];
    let innerLinks1=[];
    let adminLinks=[];
    let adminLinks1=[];
    let dokList=[];
    let dokList1=[];

    if (
      userInfo.role === "User" ||
      userInfo.role === "Admin" ||
      userInfo.role === "Inner"
    ) {
      setBrandLink("/");
    } else if (userInfo.role === "Farmer") {
      setBrandLink("/farmerpage");
    } else if (userInfo.role === "Driver") {
      setBrandLink("/driverpage");
    }
    if (!isAuth || authInProcess) {
      setAuth(
        <Link to="/signin" style={{ textDecoration: "none", color: "inherit" }}>
          <LoginTopButton className={classes.navLink}>Login</LoginTopButton>
        </Link>
      );
      setAuth2 (
        <Link to="/signin" style={{ textDecoration: "none", color: "inherit" }}>
          <LoginButton className={classes.navLink}>LogIn</LoginButton>
        </Link>
      );
    } else {
      setAuth2("")
      if (
        userInfo.role === "Inner" ||
        userInfo.role === "Admin"
      ) {
        if (userInfo.role === "Admin") {
          adminLinks=[
            <Link to="/allusers" className={classes.dropdownLink}>
              All users
            </Link>,
            <Link to="/butchers" className={classes.dropdownLink}>
              Butchers
            </Link>,
            <Link to="/archorders" className={classes.dropdownLink}>
              Archieved Orders
            </Link>,
          ];
          adminLinks1=[
            { link: "/allusers", text: "All users" },
            { link: "/butchers", text: "Butchers" },
            { link: "/archorders", text: "Archieved Orders" },
          ];
        }
        innerLinks= [
          <Link to="/allorders" className={classes.dropdownLink}>
            All orders
          </Link>,
          <Link to="/inventory" className={classes.dropdownLink}>
            Inventory
          </Link>,
          <Link to="/productionorders" className={classes.dropdownLink}>
            Production orders
          </Link>,
        ];
        innerLinks1=[
          { link: "/allorders", text: "All orders" },
          { link: "/inventory", text: "Inventory" },
          { link: "/productionorders", text: "Production orders" },
        ];
      }
      dokList=[
        <Link to="/logout" className={classes.dropdownLink}>
          Log Out
        </Link>,
        <Link to="/user" className={classes.dropdownLink}>
          Profile
        </Link>,
      ]
        .concat(innerLinks)
        .concat(adminLinks);
      dokList1=[
        { link: "/logout", text: "Log Out" },
        { link: "/user", text: "Profile" },
      ]
        .concat(innerLinks1)
        .concat(adminLinks1);
      setAuth(
        <CustomDropdown
          buttonText={` ${userInfo.name} `}
          buttonProps={{
            className: classes.navLink,
            variant: "text",
          }}
          dropdownList={dokList}
        />
      );
      setAuth1(
        <MenuExpand
          menuText={` ${userInfo.name} `}
          menuList={dokList1}
        />
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo,authInProcess]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  };


  const brandComponent = (
    <Link to={brandLink} style={{ textDecoration: "none" }}>
      <Grid
        container
        direction="row"
        justifycontent="center"
        alignitems="center"
      >
        <MbuziLogo className={classes.logo} />
        <Button className={classes.title}>mbuzi24</Button>
      </Grid>
    </Link>
  );
  let shopBarComponent = "";
  if (shopBar) {
    shopBarComponent = (
      <AppBar className={appBarClasses1}>
        <ShopBar shop={shop} />
      </AppBar>
    );
  }
  myCart = "";
  if (cart.items.length > 0) {
    myCart = (
      <Link to="/checkout2" style={{ textDecoration: "none" }}>
        <IconButton aria-label="cart">
          <Badge badgeContent={cart.items.length} color="secondary">
            <ShoppingCartIcon style={{ color: "#FFFFFF" }} />
          </Badge>
        </IconButton>
      </Link>
    );
  }

  const headerLinks = (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Link
          to="/aboutus"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <ListButton className={classes.navLink}>About Us</ListButton>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link
          to="/contact"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <ListButton className={classes.navLink}>Contact</ListButton>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link
          to="/newsupplier"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <ListButton className={classes.navLink}>For Suppliers</ListButton>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>{auth}</ListItem>
    </List>
  );

  return (
    <div>
      <AppBar className={appBarClasses}>
        <Toolbar className={classes.container}>
          {brandComponent}
          <Hidden mdDown implementation="css">
            <div style={{ width: "450px" }}>
              {shopBar ? (
                <ShopBar shop={shop} style={{ width: "400px" }} />
              ) : (
                ""
              )}
            </div>
          </Hidden>
          {myCart}
          <Hidden mdDown implementation="css">
            {headerLinks}
          </Hidden>
          <Hidden lgUp>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
            >
              <Menu />
            </IconButton>
          </Hidden>
        </Toolbar>
        <Hidden lgUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={"right"}
            open={mobileOpen}
            classes={{
              paper: classes.drawerPaper,
            }}
            onClose={handleDrawerToggle}
          >
            <div className={classes.drawerHeader}>Information</div>
            <div className={classes.appResponsive}>
              <IconButton
                onClick={handleDrawerToggle}
                className={classes.closeIcon}
              >
                {<CloseIcon />}
              </IconButton>
              <MobileMenuList auth1={auth1} auth2={auth2}/>
            </div>
          </Drawer>
        </Hidden>
      </AppBar>
      <Hidden lgUp implementation="css">
        {shopBarComponent}
      </Hidden>
    </div>
  );
}
