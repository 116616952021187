import React, { useState } from "react";
import classNames from "classnames";

import withStyles from "@material-ui/core/styles/withStyles";
import { makeStyles } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Divider from "@material-ui/core/Divider";
import Icon from "@material-ui/core/Icon";
import Popper from "@material-ui/core/Popper";

import Button from '@material-ui/core/Button';

import navbarStyle from "./navbarStyle"

const ListButton = withStyles({
  root: {
    color: "#FFFFFF",
    '&:hover': {
      color: "#FBEB00 !important",
    },
  },
})(Button);

const useStyles = makeStyles(navbarStyle);

export default function CustomDropdown(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [target, setTarget] = useState(null);

  const handleClick = (event) => {
    setOpen(!open);
    setTarget(event.target);

  };
  const handleClose = param => {
    setOpen(false);
    setTarget(null);
  };
  const handleCloseAway = event => {
    setOpen(false);
    setTarget(null);
  };

  const hoverColor = "#1C1E1E"
  const {
    buttonText,
    dropdownList,
    buttonProps
  } = props;

  const caretClasses = classNames({
    [classes.caret]: true,
    [classes.caretActive]: open,
  });
  const dropdownItem = classNames({
    [classes.dropdownItem]: true,
    [classes[hoverColor + "Hover"]]: true,

  });
  let icon = null;
  //console.log (typeof buttonIcon)
  switch (typeof buttonIcon) {
    case "object":
      icon = <props.buttonIcon className={classes.buttonIcon} />;
      break;
    case "string":
      icon = (
        <Icon className={classes.buttonIcon}>{props.buttonIcon}</Icon>
      );
      break;
    default:
      icon = null;
      break;
  }
  return (
    <div>
      <div>
        <ListButton
          aria-label="Notifications"
          aria-owns={open ? "menu-list" : null}
          aria-haspopup="true"
          {...buttonProps}
          onClick={(event) => handleClick(event)}
        >
          {icon}
          {buttonText !== undefined ? buttonText : null}
          <b className={caretClasses} />
        </ListButton>
      </div>
      <Popper
        open={open}
        anchorEl={target}
        transition
        disablePortal
        placement={
          "bottom"
        }
        className={classNames({
          [classes.popperClose]: !open,
          [classes.popperResponsive]: true
        })}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            in={open}
            id="menu-list"
            style={
                { transformOrigin: "0 0 0" }
            }
          >
            <Paper className={classes.dropdown}>
              <ClickAwayListener onClickAway={handleCloseAway}>
                <MenuList role="menu" className={classes.menuList}>
                  {dropdownList.map((propa, key) => {
                    if (propa.divider) {
                      return (
                        <Divider
                          key={key}
                          onClick={() => handleClose("divider")}
                          className={classes.dropdownDividerItem}
                        />
                      );
                    }
                    return (
                      <MenuItem
                        key={key}
                        onClick={() => handleClose(propa)}
                        className={dropdownItem}
                      >
                        {propa}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );

}

