import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Navbar from "../Navbar/Navbar";
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import HomeContent from "./Components/HomeContent";

export default function Home() {
  const userInfo = useSelector((state) => state.auth.userInfo);
  const [redirect, setRedirect] = useState(false);
  const [redirectTo, setRedirectTo] = useState(null);

  const authInProcess = useSelector((state) => state.auth.authInProcess);
  //const loading = useSelector((state) => state.reducer.loading);

  useEffect(() => {
    //console.log(' effectis');
    if (userInfo && !authInProcess) {
      //console.log('render,oli role');
      if (userInfo.role === "Farmer") {
        setRedirect(true);
        setRedirectTo("/farmerpage")
        //return <Redirect to="/farmerpage" />;
      } else if (userInfo.role === "Driver") {
        setRedirect(true);
        setRedirectTo("/driverpage")
      }
    };
  }, [authInProcess, userInfo]);

  if (redirect) {
    return <Redirect to={redirectTo}/>;
  } else {
    return (
     
        <div style={{
          textDecoration: "none",
          position: "relative",
          minHeight: '100vh',
          margin: 0,
          padding: 0,
          border: 0,
          alignItems: "center",
          background: `#FCEE23`,
        }}>
          <Helmet>
            <meta charSet="utf-8" />
            <meta name="description" content="Mbuzi24 - online butchery in Nairobi. Premium Beef, Goat and Chicken delivery, whole goats" />
            <meta name="keywords" content="mbuzi, goat, beef, chicken, meat, butchery, premium cuts" />
            <title>Online chicken, beef and goat meat delivery, fresh meat, live goat, Nairobi, Mbuzi</title>
  
          </Helmet>
          <Navbar color="transparent" brand="Mbuzi24" fixed />
          <div style={{ height: "50px" }}></div>
          <HomeContent
              headline="Farm Fresh Goat, Beef and Chicken"
              text1="Order 24/7 and schedule your"
              text2="convenient delivery time."
            />
        </div>
     
    );
  }
}
